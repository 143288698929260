<template>
  <TReview />
</template>

<script>
import { TReview } from "@templates";

export default {
  name: "PReview",
  components: { TReview },
};
</script>

<style lang="scss" scoped></style>
